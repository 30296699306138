import React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';

const T3 = React.memo(({ chrt_id, apidata, layout }) => {
  // const [somedata, SetSomeData] = useState();
  const dispatch = useDispatch();
  const filteredArray = apidata?.chart?.filter((item) => `${chrt_id}` in item);
  const emp_id = localStorage.getItem('emp_id');
  const emp_name = localStorage.getItem('emp_name');
  const dynamicStyle = {
    borderRadius: '25px',
    height: '30px',
    width: '70px',
    cursor: 'pointer',
    color: 'white'
  };

  const filteredarray = layout?.pageContent
    ?.filter((item) => item.tmplt_id !== 't3')
    .map((item) => item?.chartParams?.[0]?.chrt_id);

  const GetDataInArray = (objId) => {
    const filteredArray = apidata?.chart?.filter((item) => `${objId}` in item);
    return filteredArray;
  };

  const TilesNames = filteredarray.map((item) => GetDataInArray(item));
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);

  const HandleClick = (clickID) => {
    if (clickID?.hpr_pg_id) {
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: clickID?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          bgcolor: '#dde8ec',
          height: 'auto',
          borderRadius: '20px',
          p: 1
        }}
      >
        {/* {/ ------- /} */}
        <Grid container spacing={2}>
          <Grid item xs={12} sm container>
            <Grid item xs container direction="column" spacing={2}>
              <Grid item xs display="flex" flexWrap="wrap" justifyContent="space-between">
                <Grid item>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: 600, fontSize: '1.6rem', marginLeft: '1rem' }}
                  >
                    {
                      filteredArray?.[0]?.[chrt_id]?.chartParams?.[0]?.['chartParamsValue']?.[0]?.[
                        'prm_val'
                      ]
                    }
                  </Typography>
                </Grid>
                {TilesNames.length ? (
                  <Grid item display="flex" alignSelf="center" gap="5px">
                    <Grid item>
                      <Card
                        variant="outlined"
                        sx={{
                          ...dynamicStyle,
                          backgroundColor: layout?.pg_id === 'a2_m1' ? '#6699cc' : 'silver'
                        }}
                        onClick={() => {
                          HandleClick(layout?.pageContent?.[1]);
                        }}
                      >
                        <Box sx={{ textAlign: 'center', marginTop: '0.2em' }}>
                          <Typography component="div">
                            {TilesNames?.[0]?.[0]?.flm_tile?.chartParams?.[0]?.chartParamsValue?.[0]
                              ?.prm_val || ''}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                    <Grid item>
                      <Card
                        variant="outlined"
                        sx={{
                          ...dynamicStyle,
                          backgroundColor: layout?.pg_id === 'a2_m1_1' ? '#6699CC' : 'silver'
                        }}
                        onClick={() => {
                          HandleClick(layout?.pageContent?.[2]);
                        }}
                      >
                        <Box sx={{ textAlign: 'center', marginTop: '0.2em' }}>
                          <Typography component="div">
                            {/* {chart_data?.chartParams?.[0]?.['chartParamsValue']?.[0]?.['prm_val']} */}
                            {TilesNames?.[1]?.[0]?.slm_tile?.chartParams?.[0]?.chartParamsValue?.[0]
                              ?.prm_val || ''}
                          </Typography>
                        </Box>
                      </Card>
                    </Grid>
                  </Grid>
                ) : (
                  ''
                )}
              </Grid>
              <Grid sx={{ paddingLeft: '16px' }} display={'flex'} justifyContent={'space-between'}>
                <Grid item>
                  <Typography
                    sx={{
                      cursor: 'pointer',
                      fontWeight: 500,
                      color: 'black',
                      marginLeft: '1rem',
                      marginBottom: '1rem'
                    }}
                    variant="h6"
                  >
                    {`${emp_name} - ${emp_id}`} (Mankind)
                  </Typography>
                </Grid>
                <Grid item textAlign={'center'}>
                  <Typography variant="h6" sx={{ fontWeight: 600 }}>
                    {
                      filteredArray?.[0]?.[chrt_id]?.chartParams?.[2]?.['chartParamsValue']?.[0]?.[
                        'prm_val'
                      ]
                    }
                  </Typography>
                </Grid>
                <Grid item></Grid>
                {/* <Typography variant="h6" sx={{ fontWeight: 600, width: '200px' }}></Typography> */}
              </Grid>
            </Grid>

            {/* <Grid item>
              <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
               Flm
              </Typography>
            </Grid> */}
          </Grid>
        </Grid>
      </Box>
    </>
  );
});

T3.displayName = 'T3';
T3.propTypes = {
  chartParams: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object,
  content: PropTypes.object
};

export default T3;
