import React, { useEffect, useState, useCallback } from 'react';
import { useRef } from 'react';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { GroupOfDropdownNullPassing } from '../../../features/counter/counterSlice';
import { GroupOfDropdownValuePassing } from '../../../features/counter/counterSlice';
import DefaultMultiDrop from './DefaultMultiDrop';
import DefaultSingleDrop from './DefaultSingleDrop';
let myObject = {};
const DefaultParentDrop = ({ content, apidata }) => {
  const [copyData, setCopyData] = useState([]);
  const [filterdata, setFilterData] = useState([]);
  const [filterKey, setFilterKey] = useState([]);
  const [storeddata, setStoreddata] = useState({});
  const [applyFlag, setApplyFlage] = useState(false);

  const [fieldValues, setFieldValues] = useState({});
  // const prevFieldValuesRef = useRef();
  // const prevFilterKeyRef = useRef(filterKey);
  const prevFilterKeyLength = useRef(filterKey.length);
  const dispatch = useDispatch();
  const chart_data = apidata?.drp_dn_grp_data;

  useEffect(() => {
    if (chart_data?.length > 0 && filterdata?.length === 0 && filterKey.length === 0) {
      // original data
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([...chart_data]);
    } else if (filterdata?.length > 0 && chart_data?.length > 0 && filterKey.length >= 1) {
      //copydata and filtering based on what user has selected in dropdown
      const chart_data = apidata?.drp_dn_grp_data;
      const filteredObjects = chart_data.filter((object) => {
        return filterKey.every((key) => filterdata.includes(object[key]));
      });
      setCopyData([...filteredObjects]);
    } else if (chart_data?.length > 0 && filterKey.length === 0) {
      const chart_data = apidata?.drp_dn_grp_data;
      setCopyData([...chart_data]);
    }
  }, [chart_data, filterdata, filterKey]);

  // this will run when filterKey is empty
  useEffect(() => {
    if (filterKey.length === 0) {
      // get all keys and makes their value null in an object
      let allkeys = content?.drp_dn_grp_fld_data.map((key) => key.fld);
      const objectWithNullValues = Object.fromEntries(allkeys.map((key) => [key, null]));
      dispatch(GroupOfDropdownNullPassing(objectWithNullValues));
    }
  }, [filterKey]);

  //******************  use below code for filtering its old code  ********************* */

  // useEffect(() => {
  //   // Compare current and previous fieldValues
  //   const prevFilterKey = prevFilterKeyRef.current;
  //   if (
  //     filterKey.length >= 1 &&
  //     JSON.stringify(fieldValues) !== JSON.stringify(prevFieldValuesRef.current)
  //   ) {
  //     if (JSON.stringify(filterKey) !== JSON.stringify(prevFilterKey)) {
  //       dispatch(GroupOfDropdownValuePassing(fieldValues));
  //       prevFilterKeyRef.current = filterKey;
  //     } else if (
  //       JSON.stringify(filterKey) === JSON.stringify(prevFilterKey) &&
  //       JSON.stringify(fieldValues) === JSON.stringify(prevFieldValuesRef.current) &&
  //       filterKey.length >= 1
  //     ) {
  //       dispatch(GroupOfDropdownValuePassing(fieldValues));
  //       prevFilterKeyRef.current = filterKey;
  //     }
  //   } else if (
  //     filterKey.length >= 1 &&
  //     filterdata.length > 1 &&
  //     JSON.stringify(fieldValues) === JSON.stringify(prevFieldValuesRef.current)
  //   ) {
  //     dispatch(GroupOfDropdownValuePassing(fieldValues));
  //     prevFilterKeyRef.current = filterKey;
  //   }
  //   // Update the ref with the current fieldValues for the next comparison
  //   prevFieldValuesRef.current = fieldValues;
  // }, [fieldValues, filterKey, filterdata]);

  //******************  use above code for filtering its old code ends here ********************* */

  useEffect(() => {
    // Set up a debounce timeout variable to avoid too many dispatches in quick succession
    const timeoutId = setTimeout(() => {
      if (filterKey.length >= 1) {
        if (applyFlag) {
          dispatch(GroupOfDropdownValuePassing(fieldValues));
        }
        setApplyFlage(false);
      }
      // Check if the length of filterKey has reduced
      if (filterKey.length < prevFilterKeyLength.current && prevFilterKeyLength.current !== 1) {
        dispatch(GroupOfDropdownValuePassing(fieldValues));
        // You can perform additional logic here if needed
      }
      // Update the ref to the current length after processing
      prevFilterKeyLength.current = filterKey.length;
    }, 300); // Set a debounce delay of 300ms (you can adjust this value)
    // Cleanup the timeout if fieldValues, filterKey, or filterdata change quickly
    return () => clearTimeout(timeoutId);
  }, [fieldValues, filterKey, filterdata]); // Effect runs when these dependencies change

  const updateFieldValues = useCallback((fieldName, values) => {
    setFieldValues((prev) => {
      if (JSON.stringify(prev[fieldName]) !== JSON.stringify(values)) {
        return {
          ...prev,
          [fieldName]: values
        };
      }
      return prev;
    });
  }, []);

  // console.log('filteredArray1...', filterdata);

  return (
    <div style={{ backgroundColor: '#f5f8f9', padding: '10px' }}>
      <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        {content?.drp_dn_grp_fld_data.map((item) => (
          <>
            {item.is_multi_sel === 1 ? (
              <DefaultMultiDrop
                fld_l={item?.fld}
                chart_data={copyData}
                filterdata={filterdata}
                setFilterData={setFilterData}
                setFilterKey={setFilterKey}
                filterKey={filterKey}
                optionsToSelect={chart_data}
                setStoreddata={setStoreddata}
                storeddata={storeddata}
                myObject={myObject}
                updateFieldValues={updateFieldValues}
                fieldValues={fieldValues}
                setApplyFlage={setApplyFlage}
                DefaultValue={item?.def_val}
              />
            ) : item.is_multi_sel === 0 ? (
              <DefaultSingleDrop
                chart_data={copyData}
                fld_l={item?.fld}
                DefaultValue={item?.def_val}
                OptionsValue={chart_data}
                setFilterKey={setFilterKey}
                setFilterData={setFilterData}
                updateFieldValues={updateFieldValues}
                setApplyFlage={setApplyFlage}
              />
            ) : (
              ''
            )}
          </>
        ))}
      </Stack>
    </div>
  );
};

export default DefaultParentDrop;
DefaultParentDrop.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
