import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
export default function TextWithNewLineAndLeft({ content }) {
  return (
    // <Box display='flex' justifyContent='flex-end'>
    <Box
      sx={{ backgroundColor: content?.label?.[0]?.bg_colr, padding: '10px', width: '50%' }}
      id="testing label"
    >
      <Typography
        sx={{
          color: content?.label?.[0]?.text_colr,
          fontSize: '16px',
          fontFamily: 'Arial, Helvetica, sans-serif',
          wordWrap: 'break-word'
        }}
      >
        {content?.label?.[0]?.lbl_descr}
      </Typography>
    </Box>
    // </Box>
  );
}
TextWithNewLineAndLeft.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
