import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'firstName', headerName: 'First name', width: 100, editable: true },
  { field: 'lastName', headerName: 'Last name', width: 100, editable: true },
  { field: 'age', headerName: 'Age', type: 'number', width: 110, editable: true },
  {
    field: 'fullName',
    headerName: 'Full name',
    description: 'This column has a value getter and is not sortable.',
    sortable: false,
    width: 100
  },
  { field: 'email', headerName: 'Email', width: 100, editable: true },
  { field: 'phoneNumber', headerName: 'Phone Number', width: 100, editable: true },
  { field: 'address', headerName: 'Address', width: 100, editable: true },
  { field: 'city', headerName: 'City', width: 100, editable: true },
  { field: 'state', headerName: 'State', width: 100, editable: true },
  { field: 'zipCode', headerName: 'Zip Code', type: 'number', width: 100, editable: true },
  { field: 'country', headerName: 'Country', width: 100, editable: true },

  // Add a valueGetter to convert string dates to Date objects
  {
    field: 'dateOfBirth',
    headerName: 'Date of Birth',
    type: 'date',
    width: 100,
    editable: true,
    valueGetter: (params) => (params.row.dateOfBirth ? new Date(params.row.dateOfBirth) : null)
  },

  { field: 'gender', headerName: 'Gender', width: 100, editable: true },
  { field: 'isActive', headerName: 'Active', type: 'boolean', width: 100 },
  {
    field: 'createdAt',
    headerName: 'Created At',
    type: 'date',
    width: 100,
    valueGetter: (params) => new Date(params.row.createdAt)
  },
  { field: 'role', headerName: 'Role', width: 100, editable: true },
  { field: 'department', headerName: 'Department', width: 100, editable: true },
  { field: 'salary', headerName: 'Salary', type: 'number', width: 100, editable: true }
];

const rows = [
  {
    id: 1,
    lastName: 'Snow',
    firstName: 'Jon',
    age: 14,
    email: 'jon.snow@example.com',
    phoneNumber: '123-456-7890',
    address: 'Winterfell Castle',
    city: 'Winterfell',
    state: 'North',
    zipCode: '12345',
    country: 'Westeros',
    dateOfBirth: '2008-12-25',
    gender: 'Male',
    isActive: true,
    createdAt: '2022-01-01',
    role: 'Lord Commander',
    department: "Night's Watch",
    salary: 30000
  },
  {
    id: 2,
    lastName: 'Lannister',
    firstName: 'Cersei',
    age: 31,
    email: 'cersei.lannister@example.com',
    phoneNumber: '234-567-8901',
    address: 'Red Keep',
    city: "King's Landing",
    state: 'Crownlands',
    zipCode: '54321',
    country: 'Westeros',
    dateOfBirth: '1993-08-23',
    gender: 'Female',
    isActive: false,
    createdAt: '2021-11-15',
    role: 'Queen',
    department: 'Royal Court',
    salary: 50000
  },
  {
    id: 3,
    lastName: 'Lannister',
    firstName: 'Jaime',
    age: 31,
    email: 'jaime.lannister@example.com',
    phoneNumber: '345-678-9012',
    address: 'Red Keep',
    city: "King's Landing",
    state: 'Crownlands',
    zipCode: '54321',
    country: 'Westeros',
    dateOfBirth: '1993-08-23',
    gender: 'Male',
    isActive: true,
    createdAt: '2022-01-10',
    role: 'Kingsguard',
    department: 'Royal Guard',
    salary: 40000
  },
  {
    id: 4,
    lastName: 'Stark',
    firstName: 'Arya',
    age: 11,
    email: 'arya.stark@example.com',
    phoneNumber: '456-789-0123',
    address: 'Winterfell Castle',
    city: 'Winterfell',
    state: 'North',
    zipCode: '12345',
    country: 'Westeros',
    dateOfBirth: '2011-04-14',
    gender: 'Female',
    isActive: true,
    createdAt: '2023-03-18',
    role: 'Assassin',
    department: 'Faceless Men',
    salary: 20000
  },
  {
    id: 5,
    lastName: 'Targaryen',
    firstName: 'Daenerys',
    age: null,
    email: 'daenerys.targaryen@example.com',
    phoneNumber: '567-890-1234',
    address: 'Dragonstone Castle',
    city: 'Dragonstone',
    state: 'Crownlands',
    zipCode: '67890',
    country: 'Westeros',
    dateOfBirth: '1990-12-10',
    gender: 'Female',
    isActive: false,
    createdAt: '2020-05-10',
    role: 'Queen',
    department: 'Targaryen House',
    salary: 60000
  },
  {
    id: 6,
    lastName: 'Melisandre',
    firstName: null,
    age: 150,
    email: 'melisandre@example.com',
    phoneNumber: '678-901-2345',
    address: 'Asshai',
    city: 'Asshai',
    state: 'Shadowlands',
    zipCode: '00000',
    country: 'Essos',
    dateOfBirth: '0874-09-10',
    gender: 'Female',
    isActive: true,
    createdAt: '2019-07-12',
    role: 'Priestess',
    department: 'Lord of Light',
    salary: 80000
  },
  {
    id: 7,
    lastName: 'Clifford',
    firstName: 'Ferrara',
    age: 44,
    email: 'ferrara.clifford@example.com',
    phoneNumber: '789-012-3456',
    address: '123 Iron Bank Road',
    city: 'Braavos',
    state: 'Free Cities',
    zipCode: '34567',
    country: 'Essos',
    dateOfBirth: '1980-11-01',
    gender: 'Male',
    isActive: true,
    createdAt: '2021-09-30',
    role: 'Banker',
    department: 'Iron Bank',
    salary: 70000
  },
  {
    id: 8,
    lastName: 'Frances',
    firstName: 'Rossini',
    age: 36,
    email: 'rossini.frances@example.com',
    phoneNumber: '890-123-4567',
    address: '456 Free Cities Lane',
    city: 'Pentos',
    state: 'Free Cities',
    zipCode: '45678',
    country: 'Essos',
    dateOfBirth: '1987-06-17',
    gender: 'Female',
    isActive: true,
    createdAt: '2023-02-15',
    role: 'Trader',
    department: 'Essos Merchants',
    salary: 45000
  },
  {
    id: 9,
    lastName: 'Roxie',
    firstName: 'Harvey',
    age: 65,
    email: 'harvey.roxie@example.com',
    phoneNumber: '901-234-5678',
    address: '789 Sunspear Street',
    city: 'Sunspear',
    state: 'Dorne',
    zipCode: '78901',
    country: 'Westeros',
    dateOfBirth: '1959-12-30',
    gender: 'Female',
    isActive: false,
    createdAt: '2021-08-22',
    role: 'Merchant',
    department: 'Dorne Traders',
    salary: 55000
  },

  // Added more rows below
  {
    id: 10,
    lastName: 'Martell',
    firstName: 'Oberyn',
    age: 43,
    email: 'oberyn.martell@example.com',
    phoneNumber: '123-567-8901',
    address: 'Dorne Palace',
    city: 'Sunspear',
    state: 'Dorne',
    zipCode: '78901',
    country: 'Westeros',
    dateOfBirth: '1981-10-12',
    gender: 'Male',
    isActive: true,
    createdAt: '2023-03-22',
    role: 'Prince',
    department: 'Royal Court',
    salary: 50000
  }
];

export default function MuiSimpleTable() {
  return (
    <Box sx={{ height: 400, width: '100%', overflowX: 'scroll' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5
            }
          }
        }}
        pageSizeOptions={[5]}
        checkboxSelection
        disableRowSelectionOnClick
      />
    </Box>
  );
}
