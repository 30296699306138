import React, { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import { useDispatch, useSelector } from 'react-redux';
export default function ShowCardTiles({ apidata, content, layout }) {
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const dispatch = useDispatch();

  const chart_data = useMemo(() => {
    if (content?.chrt_id) {
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[content?.chrt_id]);
      return filterd_data?.[0]?.[content?.chrt_id];
    }
  }, [apidata]);

  const handleClickOpen = (chart_data) => {
    if (chart_data?.hpr_pg_id) {
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: chart_data?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };

  return (
    <Box
      sx={{
        width: '220px',
        borderRadius: '30px',
        backgroundColor: content?.bg_colr,
        cursor: 'pointer',
        height: '140px'
      }}
      onClick={() => {
        handleClickOpen(chart_data);
      }}
    >
      <>
        <Typography
          gutterBottom
          fontSize="16px"
          padding="10px"
          textAlign="-webkit-center"
          fontWeight="bold"
        >
          {chart_data?.chartParams?.[0]?.chartParamsValue?.[0]?.prm_val}
        </Typography>
        {chart_data?.chartParams?.slice(1).map((item, index, array) => (
          <Box display="flex" justifyContent="center" key={index}>
            {index % 2 == 0 && (
              <Stack direction="row" spacing={8}>
                <Typography marginBottom="1rem">{item?.chartParamsValue?.[0]?.prm_val}</Typography>
                <Typography> {array[index + 1]?.chartParamsValue?.[0]?.prm_val}</Typography>
              </Stack>
            )}
          </Box>
        ))}
      </>
    </Box>
  );
}
ShowCardTiles.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
