import React, { useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

export default function SingleDrop({
  fld_l,
  DefaultValue,
  OptionsValue,
  updateFieldValues,
  setFilterKey,
  setFilterData,
  setApplyFlage
}) {
  // Initialize with DefaultValue as a single value
  const [age, setAge] = useState(DefaultValue);
  const chartFieldValues = [...new Set(OptionsValue?.map((object) => object?.[fld_l]))];
  const DropValues = chartFieldValues;

  // Function to handle dropdown change (only current value)
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setApplyFlage((prev) => !prev);
    setAge(selectedValue); // Set the new selected value
    updateFieldValues(fld_l, [selectedValue]); // Send only the current selected value to updateFieldValues

    // Update filter key and filter data
    setFilterKey((prev) => [...prev, fld_l]);
    setFilterData((prev) => {
      const filteredData = prev.filter((value) => !chartFieldValues.includes(value));
      return [...filteredData, selectedValue.trim()]; // Only include the current value
    });
  };

  useEffect(() => {
    updateFieldValues(fld_l, [age]); // Ensure only the current value is sent
  }, [age, fld_l, updateFieldValues]);

  return (
    <Box
      sx={{
        backgroundColor: '#f5f5f5',
        padding: '3px',
        width: 'fit-content'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography>{fld_l.charAt(0).toUpperCase() + fld_l.slice(1)}</Typography>
      </Stack>

      <FormControl sx={{ width: 100 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          displayEmpty
          onChange={handleChange}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

SingleDrop.propTypes = {
  fld_l: PropTypes.string.isRequired,
  DefaultValue: PropTypes.string.isRequired,
  OptionsValue: PropTypes.array.isRequired,
  updateFieldValues: PropTypes.func.isRequired,
  setFilterKey: PropTypes.func.isRequired,
  setFilterData: PropTypes.func.isRequired,
  setApplyFlage: PropTypes.func
};
