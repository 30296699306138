import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import { useDispatch, useSelector } from 'react-redux';
export default function MuiCardTiels({ apidata, content, layout }) {
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const dispatch = useDispatch();

  const chart_data = useMemo(() => {
    if (content?.chrt_id) {
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[content?.chrt_id]);
      return filterd_data?.[0]?.[content?.chrt_id];
    }
  }, [apidata]);

  const handleClickOpen = (chart_data) => {
    if (chart_data?.hpr_pg_id) {
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: chart_data?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };

  return (
    <Card
      sx={{
        maxWidth: 345,
        borderRadius: '30px',
        backgroundColor: content?.bg_colr,
        cursor: 'pointer'
      }}
      onClick={() => {
        handleClickOpen(chart_data);
      }}
    >
      <CardContent sx={{ height: 170 }}>
        <Typography gutterBottom variant="h5">
          {chart_data?.chartParams[0].chartParamsValue?.[0]?.prm_val}
        </Typography>
        {chart_data?.chartParams.slice(1).map((item, index, array) => (
          <>
            {index % 2 == 0 && (
              <Typography>
                {item?.chartParamsValue?.[0]?.prm_val} {'      '}
                {array[index + 1]?.chartParamsValue?.[0]?.prm_val}
              </Typography>
            )}
          </>
        ))}
      </CardContent>
    </Card>
  );
}
MuiCardTiels.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
