/* eslint-disable no-unused-vars */
import { useContext, useEffect, useState } from 'react';
import Http from '../../utils/http';
import PropTypes from 'prop-types';

// nav-bar / mui imports
import {
  AppBar,
  Box,
  CssBaseline,
  Divider,
  IconButton,
  Toolbar,
  Button,
  Tooltip,
  Typography
} from '@mui/material';

// MUI icons
import MenuIcon from '@mui/icons-material/Menu';

// react-router
import { useNavigate, useParams } from 'react-router-dom';
import MobileNav from './MobileNav';
import DesktopNav from './DesktopNav';
import { PageIdContext } from '../../context';
import MenuDropdown from './MenuDropdown';

const Navbar = ({ appName, menu }) => {
  const navigate = useNavigate();

  const { slug } = useParams();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  // const [appName, setAppName] = useState(null);
  const [menuResponse, setMenuResponse] = useState(null);

  const { setPageId } = useContext(PageIdContext);

  /**
   * handler functions start
   */

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  function parseMenuArray(menuArray) {
    const menuMap = {};
    const result = [];

    // Create a map of menu items using their mnu_id
    for (const item of menuArray) {
      const { mnu_id } = item;
      menuMap[mnu_id] = { ...item, children: [] };
    }

    // Iterate over the menu items again to build the hierarchy
    for (const item of menuArray) {
      const { prnt_mnu_id } = item;
      const menuItem = menuMap[prnt_mnu_id];

      if (menuItem) {
        menuItem.children.push(menuMap[item.mnu_id]);
      } else {
        result.push(menuMap[item.mnu_id]);
      }
    }

    return result;
  }

  const fetchMenuList = async (menu) => {
    // const response = await Http.get('get-client-app-menu');
    // console.log("response.data.data",response.data.data,'menu',menu)
    setMenuItems(parseMenuArray(menu));
    setMenuResponse(menu);
    const res = [];
    parseMenuArray(menu)?.filter((item) => {
      res?.push({
        ...item,
        hasChildMenuListIcon: item?.children?.some((child) => child?.m_icn)
      });
    });
    setMenuItems(res);
  };

  // const fetchAppsList = async () => {
  //   const response = await Http.get('get-client-apps');
  //   const appList = response?.data?.data;
  //   if (appList?.length > 0) {
  //     const getAppName = appList.filter((app) => app.a_id === appId);
  //     setAppName(getAppName[0]?.a_nm);
  //   }
  // };

  /**
   * handler functions end
   */

  useEffect(() => {
    fetchMenuList(menu);
    // fetchAppsList();
  }, []);

  useEffect(() => {
    setPageId(menuResponse?.find((item) => item?.slug === slug)?.pg_id || '');
  }, [slug, menuResponse]);

  return (
    <Box sx={{ display: 'flex', '& .MuiToolbar-root': { minHeight: '50px' } }}>
      <CssBaseline />
      <AppBar component="nav">
        {/* first/top menu bar, pretty much common - no need to split between mobile and desktop */}
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Tooltip title="go to landing page">
            <Button
              color="inherit"
              aria-label="go to landing page"
              edge="start"
              onClick={() => navigate(`/`)}
              sx={{ display: { xs: 'none', sm: 'flex' } }}
            >
              {/* <Typography>LCP</Typography> */}
              <></>
            </Button>
          </Tooltip>
          {/*
          
          ////////////////// app name here
          */}
          {/* <Typography>{appName}</Typography> */}
          <Box>
            <MenuDropdown />
          </Box>
        </Toolbar>

        {/* second/middle menu bar below*/}
        {/* BreadCrumbsNav below*/}
        {menuItems?.length ? (
          <>
            {' '}
            <Divider color="white" sx={{ display: { xs: 'none', sm: 'flex' } }} />
            <Toolbar sx={{ display: { xs: 'none', sm: 'flex' } }}>
              <DesktopNav menuItems={menuItems} />
            </Toolbar>
            <Divider color="white" sx={{ display: { xs: 'none', sm: 'flex' } }} />
          </>
        ) : (
          ''
        )}
      </AppBar>
      <MobileNav
        handleDrawerToggle={handleDrawerToggle}
        menuItems={menuItems}
        mobileOpen={mobileOpen}
      />
    </Box>
  );
};
Navbar.propTypes = {
  appName: PropTypes.string,
  menu: PropTypes.array
};

export default Navbar;
