import React, { useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { LuFilterX } from 'react-icons/lu';
export default function SingleDropDowns({
  fld_l,
  DefaultValue,
  apidata,
  updateFieldValues,
  setKeyToBeFilter,
  setValueToFilter,
  setFlage,
  // keytobefilter,
  item,
  fieldValues,
  content
}) {
  const [age, setAge] = React.useState(DefaultValue.split(','));
  const DropDownOptions = apidata?.map((item) => item?.[fld_l]);
  const uniqueArrayDropDownOptions = [...new Set(DropDownOptions)];
  const DropValues = uniqueArrayDropDownOptions;

  const handleChange = (event, fld_l_Values) => {
    setKeyToBeFilter(fld_l_Values);
    setValueToFilter(event.target.value);
    setAge(event.target.value);
    setFlage(false);
  };

  const SingleDropDownsFilterClear = (DefaultValue) => {
    setKeyToBeFilter('');
    setAge(DefaultValue.split(','));
    setFlage(true);
  };

  useEffect(() => {
    if (Array.isArray(age)) {
      updateFieldValues(fld_l, age);
    } else {
      updateFieldValues(fld_l, [age]);
    }
  }, [age, fld_l]);

  return (
    <Box
      sx={{
        backgroundColor: content?.bg_colr,
        padding: '3px',
        width: 'fit-content'
      }}
    >
      <Stack direction="row" spacing={1}>
        <Typography>{fld_l.charAt(0).toUpperCase() + fld_l.slice(1)}</Typography>
        {item?.cancel_icon === 'y' && (
          <LuFilterX
            cursor="pointer"
            color={fieldValues?.[item.fld]?.[0] === item?.def_val ? 'black' : 'red'}
            onClick={() => SingleDropDownsFilterClear(DefaultValue)}
          />
        )}
      </Stack>

      <FormControl sx={{ width: 100 }} size="small">
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          displayEmpty
          onChange={(event) => handleChange(event, fld_l, age)}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
SingleDropDowns.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  DefaltValueDropdown: PropTypes.array,
  DefaultValue: PropTypes.string,
  OptionsValue: PropTypes.string,
  updateFieldValues: PropTypes.func,
  updateFieldValues1: PropTypes.func,
  setFilterKey: PropTypes.func,
  fieldValues: PropTypes.object,
  setKeyToBeFilter: PropTypes.func,
  setValueToFilter: PropTypes.func,
  keytobefilter: PropTypes.string,
  setFlage: PropTypes.func,
  item: PropTypes.object,
  content: PropTypes.object
};
