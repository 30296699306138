import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { IoMdArrowDropdown } from 'react-icons/io';
import Check from '@mui/icons-material/Check';

export default function BasicMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedValue, setSelectedValue] = useState('');
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event, value) => {
    if (value) {
      setSelectedValue(value);
    }
    setAnchorEl(null);
  };
  // console.log('selectedValue...', selectedValue);

  return (
    <div>
      <IoMdArrowDropdown
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ cursor: 'pointer' }}
      />
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <MenuItem onClick={(event) => handleClose(event, 'Profile')}>
          {selectedValue === 'Profile' ? <Check /> : null}
          Include values
        </MenuItem>
        <MenuItem onClick={(event) => handleClose(event, 'My account')}>
          {selectedValue === 'My account' ? <Check /> : null}Exclude values
        </MenuItem>
      </Menu>
    </div>
  );
}
