import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
export default function ButtonAsBox({ content }) {
  return (
    <Box sx={{ backgroundColor: content?.label?.[0]?.bg_colr, padding: '20px' }}>
      <Typography
        sx={{ color: content?.label?.[0]?.text_colr, textAlign: 'center', fontWeight: 'bold' }}
      >
        {content?.label?.[0]?.lbl_descr}
      </Typography>
    </Box>
  );
}
ButtonAsBox.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
