import React from 'react';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
import { useSelector, useDispatch } from 'react-redux';
import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';

const SelectableMiniTabs = React.memo(({ apidata, layout, content }) => {
  const dispatch = useDispatch();

  const filteredarray = layout?.pageContent?.map((item) => item?.chartParams?.[0]?.chrt_id);
  const GetDataInArray = (objId) => {
    const filteredArray = apidata?.chart?.filter((item) => `${objId}` in item);
    return filteredArray;
  };
  const TilesNames = filteredarray.map((item) => GetDataInArray(item));
  const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);

  const HandleClick = (clickID) => {
    if (clickID?.hpr_pg_id) {
      if (hyperdata.length) {
        RemoveProgressBarHyperlinkObject(hyperdata);
      }
      dispatch(ProgressBarHyperLink({ ['hyperPage_id']: clickID?.hpr_pg_id }));
      dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    }
  };

  const RemoveProgressBarHyperlinkObject = (hyperdata) => {
    dispatch(RemoveProgressBarHyperLink(hyperdata));
    dispatch(RemoveProgressBarHyperLink());
  };
  return (
    <>
      <Box
        sx={{
          bgcolor: content?.bg_colr,
          borderRadius: '20px',
          p: 1,
          height: '35px',
          width: '120px',
          cursor: 'pointer',
          color: 'white',
          display: 'flex',
          justifyContent: 'center'
        }}
        onClick={() => {
          HandleClick(layout?.pageContent?.[0]);
        }}
        id="box1"
      >
        <Box id="box2">
          <Typography color="black">
            {TilesNames?.[0]?.[0]?.[filteredarray?.[0]]?.chartParams?.[0]?.chartParamsValue?.[0]
              ?.prm_val || ''}
          </Typography>
        </Box>
      </Box>
    </>
  );
});

SelectableMiniTabs.displayName = 'SelectableMiniTabs';
SelectableMiniTabs.propTypes = {
  chartParams: PropTypes.array,
  layout: PropTypes.object,
  chrt_id: PropTypes.string,
  apidata: PropTypes.object,
  content: PropTypes.object
};

export default SelectableMiniTabs;
