import React, { useMemo } from 'react';
import Card from '@mui/material/Card';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
// import { useDispatch, useSelector } from 'react-redux';
// import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
// import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import SecondeLayout from '../SecondLayout/SecondeLayout';
import { fetchContentData, fetchSectionData } from '../../utils/SecondLayoutApiCall';

export const MiniTiels = ({ apidata, content }) => {
  const [open, setOpen] = React.useState(false);
  const [hyprerlinkPageID, setHyprerlinkPageID] = React.useState({});

  // const dispatch = useDispatch();
  // const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);

  const chart_data = useMemo(() => {
    if (content?.chrt_id) {
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[content?.chrt_id]);
      return filterd_data?.[0]?.[content?.chrt_id];
    }
  }, [apidata]);

  const dynamicStyle = {
    borderRadius: '15px',
    height: '43px',
    width: '70px',
    cursor: 'pointer',
    backgroundColor: content?.bg_colr ? content?.bg_colr : '#E5F3FF',
    color: content?.text_colr ? content?.text_colr : '#322B7C'
  };

  const handleClickOpen = async (content) => {
    //******************redux hyperlink calling for minitiels */
    // let DynamicObjectForBar = {
    //   main_tab: layout?.lbl,
    //   sub_tab: item?.lbl,
    //   parameter_key_name: chart_data?.chartDetail?.[1]?.fld_id,
    //   parameter_key_value: data?.[chart_data?.chartDetail?.[1]?.fld_id]
    // };
    // if (content?.chartParams?.[0]?.hpr_pg_id) {
    //   // below condition is for empty redux state
    //   if (hyperdata.length) {
    //     RemoveProgressBarHyperlinkObject(hyperdata);
    //   }
    //   dispatch(ProgressBarHyperLink({ ['hyperPage_id']: content?.chartParams?.[0]?.hpr_pg_id }));
    //   dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    //   // dispatch(ProgressBarHyperLink({ ['BarInfo']: DynamicObjectForBar }));
    // }

    //************************secondlayout pop-up page for minitiels***************
    if (content?.chartParams?.[0]?.hpr_pg_id) {
      const contentdata = await fetchContentData(content?.chartParams?.[0]?.hpr_pg_id);
      const sectiondata = await fetchSectionData(content?.chartParams?.[0]?.hpr_pg_id);
      setHyprerlinkPageID({
        HyperLinkPageId: content?.chartParams?.[0]?.hpr_pg_id,
        DynamicObjectForBar: {},
        fetch_Content_Data: contentdata,
        fetch_Section_Data: sectiondata
      });
      setOpen(true);
    }
  };

  // const RemoveProgressBarHyperlinkObject = (hyperdata) => {
  //   dispatch(RemoveProgressBarHyperLink(hyperdata));
  //   dispatch(RemoveProgressBarHyperLink());
  // };
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Box sx={{ display: 'grid' }}>
          <Box sx={{ flexGrow: 1, display: 'grid' }}>
            <Grid container spacing={2}>
              <Grid item>
                <Card
                  variant="outlined"
                  sx={dynamicStyle}
                  onClick={() => {
                    handleClickOpen(content);
                  }}
                >
                  <Box sx={{ textAlign: 'center', marginTop: '0.5em' }}>
                    <Typography component="div" fontWeight="bold">
                      {chart_data?.chartParams?.[0]?.['chartParamsValue']?.[0]?.['prm_val']}
                    </Typography>
                  </Box>
                </Card>
              </Grid>

              <Grid item>
                <Typography
                  variant="subtitle2"
                  sx={{ marginTop: '0.7rem', color: 'navy', fontWeight: 'bold' }}
                >
                  {chart_data?.chartParams?.[1]?.['chartParamsValue']?.[0]?.['prm_val']}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      {open && <SecondeLayout open={open} setOpen={setOpen} Page_id_input={hyprerlinkPageID} />}
    </>
  );
};
MiniTiels.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
