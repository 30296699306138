import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import MiniSizeTableChild from './MiniSizeTableChild';
import { Divider } from '@mui/material';
const MiniSizeTableParent = ({ layout, apidata, content }) => {
  return (
    <>
      <Typography
        variant="button"
        display="block"
        fontWeight="bold"
        fontSize="20px"
        textAlign="left"
        marginBottom="3rem"
        color="#3b459f"
        marginLeft="4rem"
      >
        {layout?.lbl}
      </Typography>
      <Box display="flex" justifyContent="center">
        <Box
          sx={{
            p: 2,
            backgroundColor: content?.bg_colr,
            borderRadius: '40px'
          }}
        >
          <Typography fontWeight="bold" textAlign="Left" fontSize="20px">
            {content?.tbl_nm}
          </Typography>
          <Divider
            sx={{
              borderBottomWidth: '1px',
              borderColor: '#000000',
              marginTop: '1rem',
              marginBottom: '1rem'
            }}
          />
          <MiniSizeTableChild layout={layout} apidata={apidata} content={content} />
        </Box>
      </Box>
    </>
  );
};

export default MiniSizeTableParent;
MiniSizeTableParent.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
