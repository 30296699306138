import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { ToGetSortColumn } from '../../features/counter/counterSlice';
import ChildTable from './ChildTable';
const TableWithPaddingColumn = ({ item, apidata }) => {
  // item is layout object here....
  const [tableData, setTableData] = useState([]);
  const [tablename, setTableName] = useState('');
  const [SortColumnName, setSortColumnName] = useState({ column_order: 'asc' });
  // const ColumnSortOfTableValue = useSelector((state) => state.counter.ColumnSortOfTable);
  const ColumnSortOfTableValue1 = useSelector((state) => state.counter.getGotTableSortingData);
  // const ShowDefaultTable = item?.pageContent?.[0]?.got?.filter((column) => column.is_def === 1);
  const prevSortValuesRef = useRef();
  const dispatch = useDispatch();
  useEffect(() => {
    if (tablename) {
      const filterdColumn = item?.pageContent?.[0]?.got?.filter(
        (column) => column.own_table === tablename
      );
      setTableData(filterdColumn);
    } else {
      const ShowDefaultTable = item?.pageContent?.[0]?.got?.filter((column) => column.is_def === 1);
      setTableData(ShowDefaultTable);
    }
  }, [tablename]);

  useEffect(() => {
    let objectLength = Object.keys(SortColumnName)?.length;
    if (
      JSON.stringify(SortColumnName) !== JSON.stringify(prevSortValuesRef.current) &&
      objectLength > 1
    ) {
      prevSortValuesRef.current = SortColumnName;

      dispatch(ToGetSortColumn(SortColumnName));
    } else if (
      JSON.stringify(SortColumnName) === JSON.stringify(prevSortValuesRef.current) &&
      objectLength > 1
    ) {
      prevSortValuesRef.current = SortColumnName;

      dispatch(ToGetSortColumn(SortColumnName));
    }
  }, [SortColumnName]);

  useEffect(() => {
    if (ColumnSortOfTableValue1?.length) {
      return;
    }
  }, [ColumnSortOfTableValue1]);

  return (
    <>
      <ChildTable
        ShowDefaultTable={tableData}
        setTableName={setTableName}
        tablename={tablename}
        apidata={apidata}
        content={tableData}
        setSortColumnName={setSortColumnName}
        item={item}
      />
    </>
  );
};

export default TableWithPaddingColumn;
TableWithPaddingColumn.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  item: PropTypes.object,
  cell: PropTypes.shape({
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    getValue: PropTypes.func.isRequired
    // Add other expected properties with appropriate types
  }).isRequired
};
