import * as React from 'react';
import PropTypes from 'prop-types';
export default function ShowImages({ content }) {
  return (
    <>
      <img src={content?.img_url} alt="img" width="200" />
    </>
  );
}
ShowImages.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object
};
