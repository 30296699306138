import * as React from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
// import { useDispatch } from 'react-redux';
// import { ProgressBarHyperLink } from '../../features/counter/counterSlice';
// import { RemoveProgressBarHyperLink } from '../../features/counter/counterSlice';
import SecondeLayout from '../SecondLayout/SecondeLayout';
import { fetchContentData, fetchSectionData } from '../../utils/SecondLayoutApiCall';

function LinearProgressWithLabel({
  value,
  index,
  chart_data,
  data,
  layout,
  item,
  setOpen,
  setHyprerlinkPageData
}) {
  const ProgressBarColoring = [
    '#5e09e9',
    '#f2c737',
    '#5593f2',
    '#4e79a7',
    '#4e79a7',
    '#8cd17d',
    '#ffbe7d'
  ];
  // const dispatch = useDispatch();

  const DivededValue = item?.pageContent?.[0]?.chartParams
    ?.filter((item) => item.prm_val)
    .map((data) => data.prm_val);

  // const hyperdata = useSelector((state) => state.counter.hyperlink_hpr_pg_id);
  const handleClickOpen = async () => {
    let DynamicObjectForBar = {
      main_tab: layout?.lbl,
      sub_tab: item?.lbl,
      parameter_key_name: chart_data?.chartDetail?.[1]?.fld_id,
      parameter_key_value: data?.[chart_data?.chartDetail?.[1]?.fld_id]
    };

    // if (chart_data?.chartDetail?.[0]?.hpr_pg_id) {
    //   // below condition is for empty redux state
    //   if (hyperdata.length) {
    //     RemoveProgressBarHyperlinkObject(hyperdata);
    //   }

    //   dispatch(ProgressBarHyperLink({ ['hyperPage_id']: chart_data?.chartDetail?.[0]?.hpr_pg_id }));
    //   dispatch(ProgressBarHyperLink({ ['DefaultBrowserPageId']: layout?.pg_id }));
    //   dispatch(ProgressBarHyperLink({ ['BarInfo']: DynamicObjectForBar }));
    // }

    //************************secondlayout pop-up page for minitiels***************
    const contentdata = await fetchContentData(chart_data?.chartDetail?.[0]?.hpr_pg_id);
    const sectiondata = await fetchSectionData(chart_data?.chartDetail?.[0]?.hpr_pg_id);

    setHyprerlinkPageData({
      HyperLinkPageId: chart_data?.chartDetail?.[0]?.hpr_pg_id,
      DynamicObjectForBar: DynamicObjectForBar,
      fetch_Content_Data: contentdata,
      fetch_Section_Data: sectiondata
    });

    // setHyprerlinkPageID(chart_data?.chartDetail?.[0]?.hpr_pg_id);
    setOpen(true);
  };

  // const RemoveProgressBarHyperlinkObject = (hyperdata) => {
  //   dispatch(RemoveProgressBarHyperLink(hyperdata));
  //   dispatch(RemoveProgressBarHyperLink());
  // };

  const FieldWiseLabel = chart_data?.chartDetail?.[1]?.fld_id;

  // const GraphTestColoring = ({ valueColor }) => {
  //   return valueColor;
  // };

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <Box sx={{ minWidth: 35, display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ padding: '0.5rem' }}>
              <Typography fontWeight="bold" color="text.secondary">
                {data?.[FieldWiseLabel]}
              </Typography>
              <Typography>
                {chart_data?.chartTableData?.[index][DivededValue[0]]}/
                {chart_data?.chartTableData?.[index][DivededValue[1]]}
              </Typography>
            </Box>
            <Box sx={{ alignSelf: 'center', marginRight: '5rem' }}>
              <Typography variant="body2" color="text.secondary" fontWeight="bold">{`(${Math.round(
                value
              )}%)`}</Typography>
            </Box>
          </Box>

          <Tooltip title={Object.keys(data)[0] + ' : ' + data[Object.keys(data)[0]]}>
            <LinearProgress
              onClick={() => handleClickOpen(chart_data)}
              variant="determinate"
              sx={{
                height: '10px',
                backgroundColor: 'white',
                cursor: 'pointer',
                '& .MuiLinearProgress-bar': {
                  backgroundColor: ProgressBarColoring[index] // Dynamic color for the progress bar
                },
                position: 'relative',
                textAlign: 'center',
                '&::before': {
                  content: '""',
                  position: 'absolute',
                  top: '50%',
                  left: 0,
                  right: 0,
                  height: '2px' /* Adjust the height as needed */,
                  backgroundColor: 'lightgray'
                }
              }}
              // {...props}
              value={value}
              // color={allcolors[index]}
            />
          </Tooltip>
        </Box>
      </Box>
    </>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  index: PropTypes.number,
  chart_data: PropTypes.object,
  data: PropTypes.array,
  apidata: PropTypes.array,
  layout: PropTypes.object,
  item: PropTypes.object,
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  setHyprerlinkPageData: PropTypes.func
};

export default function GraphTest({ graphdata, chart_data, apidata, layout, item }) {
  const [open, setOpen] = React.useState(false);
  const [hyprerlinkPageID, setHyprerlinkPageData] = React.useState({});
  return (
    <>
      <Stack sx={{ width: '100%', height: '330px', overflowY: 'auto' }} spacing={1}>
        {graphdata?.map((data, index) => (
          <LinearProgressWithLabel
            key={index}
            value={data?.[Object.keys(data)[0]]}
            index={index}
            chart_data={chart_data}
            data={data}
            apidata={apidata}
            layout={layout}
            item={item}
            open={open}
            setOpen={setOpen}
            setHyprerlinkPageData={setHyprerlinkPageData}
          />
        ))}
      </Stack>
      {open && <SecondeLayout open={open} setOpen={setOpen} Page_id_input={hyprerlinkPageID} />}
    </>
  );
}

GraphTest.propTypes = {
  graphdata: PropTypes.array,
  chart_data: PropTypes.object,
  apidata: PropTypes.array,
  layout: PropTypes.object,
  item: PropTypes.object
};
