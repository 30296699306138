import React, { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import BasicMenu from '../MuiMenu/Menu';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { LuFilterX } from 'react-icons/lu';
import { useDispatch } from 'react-redux';
// import { ToGetDropDownValueYear } from '../../features/counter/counterSlice';
import { ToGetallMonth } from '../../features/counter/counterSlice';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  variant: 'menu'
};

function MonthAllCheckMark({ fld_id, fld_l, apidata }) {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState([]);
  const filteredArray = apidata?.fields?.filter((item) => item?.fld_id === `${fld_id}`);
  const DropValues = filteredArray?.[0]?.dropdown?.map((item) => item.display_name);
  const options = DropValues;
  const isAllSelected = options?.length > 0 && selected.length === options?.length;

  // to convert month into string'int..
  const monthMapping = {
    Jan: '01',
    Feb: '02',
    Mar: '03',
    Apr: '04',
    May: '05',
    Jun: '06',
    Jul: '07',
    Aug: '08',
    Sep: '09',
    Oct: '10',
    Nov: '11',
    Dec: '12'
  };

  // Step 2: Function to convert month names to integers
  function convertMonthsToInt(monthArray) {
    return monthArray.map((month) => monthMapping[month] || null);
  }

  const handleChange = (event) => {
    const value = event.target.value;
    if (value[value.length - 1] === 'all') {
      setSelected(selected.length === options?.length ? [] : options);
      return;
    }
    setSelected(value);
  };

  const handleChangeRedux = (selected) => {
    if (fld_l === 'Month') {
      const months = selected;
      const monthInts = convertMonthsToInt(months);
      dispatch(ToGetallMonth(monthInts));
      setSelected(selected);
    }
  };

  //set year and month as default selected
  useEffect(() => {
    if (fld_l === 'Month' && DropValues) {
      setSelected(DropValues);
    } else {
      setSelected([]);
    }
  }, [apidata?.fields?.length]);

  return (
    <Box sx={{ backgroundColor: 'transparent', padding: '3px' }}>
      <Stack direction="row" spacing={1} justifyContent="space-between">
        <Typography fontWeight="bold">{fld_l}</Typography>
        <Stack direction="row" spacing={1} sx={{ marginTop: '10px' }}>
          <Box>
            <LuFilterX />
          </Box>
          <BasicMenu />
        </Stack>
      </Stack>

      <FormControl sx={{ width: 155, minWidth: '-webkit-fill-available' }} size="small">
        <InputLabel id="demo-multiple-checkbox-label">{}</InputLabel>
        <Select
          labelId="mutiple-select-label"
          multiple
          value={selected}
          onChange={handleChange}
          // renderValue={(selected) => selected.join(', ')}
          renderValue={(selected) => (isAllSelected ? 'All' : selected.join(', '))}
          MenuProps={MenuProps}
        >
          <MenuItem value="all" sx={{ padding: '0' }}>
            <ListItemIcon>
              <Checkbox
                checked={isAllSelected}
                indeterminate={selected?.length > 0 && selected?.length < options?.length}
              />
            </ListItemIcon>
            <ListItemText primary="Select All" />
          </MenuItem>

          {options?.map((option) => (
            <MenuItem key={option} value={option} sx={{ padding: '0' }}>
              <ListItemIcon>
                <Checkbox checked={selected.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
          <Stack direction="row" spacing={2} justifyContent="center">
            <Button
              variant="outlined"
              sx={{ color: '#000000' }}
              onClick={() => {
                handleChangeRedux(selected);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              sx={{ color: '#000000' }}
              onClick={() => {
                handleChangeRedux(selected, fld_l);
              }}
            >
              Apply
            </Button>
          </Stack>
        </Select>
      </FormControl>
    </Box>
  );
}

export default React.memo(MonthAllCheckMark);

MonthAllCheckMark.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object
};
