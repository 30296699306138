import React, { useMemo } from 'react';

import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { IoTriangle } from 'react-icons/io5';
import { TbTriangleInvertedFilled } from 'react-icons/tb';

export default function MiniSizeTableChild({ layout, content, apidata }) {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  return (
    <div>
      {chart_data?.map((item) => (
        <>
          {layout?.pageContent?.[0]?.tableData.map((child) => (
            <>
              <div
                style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', marginBottom: '10px' }}
              >
                <Typography sx={{ fontWeight: 'bold', fontSize: '20px' }}>
                  {
                    layout?.pageContent?.[0]?.tableFieldData?.find(
                      (item) => item?.fld_id === child?.fld_id
                    )?.fld_l
                  }
                </Typography>

                <Typography
                  sx={{
                    color: item?.[child?.text_color],
                    fontWeight: 'bold',
                    textAlign: 'end',
                    marginLeft: '90px',
                    fontSize: '20px'
                  }}
                >
                  {child?.icon_pos === 'left' &&
                    ((item?.[child?.icon] === 'up' && <IoTriangle />) ||
                      (item?.[child?.icon] === 'down' && <TbTriangleInvertedFilled />))}
                  {item?.[child?.fld_id]}
                  {child?.icon_pos === 'right' &&
                    ((item?.[child?.icon] === 'up' && <IoTriangle />) ||
                      (item?.[child?.icon] === 'down' && <TbTriangleInvertedFilled />))}
                </Typography>
              </div>
            </>
          ))}
        </>
      ))}
    </div>
  );
}

MiniSizeTableChild.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object
};
