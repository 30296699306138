import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import '../../Styles/Styles.css';

export default function CellClickableTable({ content, apidata }) {
  const columns = useMemo(() => {
    if (!content || !content.tableData) {
      // Return an empty array if content or tableData is not available
      return [];
    }
    return content?.tableData?.map((item, index) => ({
      field: item.fld_id,
      headerName: content?.tableFieldData?.[index]?.fld_l,
      headerAlign: 'left',
      minWidth: 180,
      sortable: false,
      flex: 1,
      renderHeader: (params) => (
        <div>
          <Stack direction="column" alignItems="center">
            <Box sx={{ width: '100%' }}>
              <Typography fontWeight="bold" fontSize="0.9rem" color={item?.col_hdr_text_colr}>
                {params?.colDef?.headerName}
              </Typography>
            </Box>
          </Stack>
        </div>
      ),
      renderCell: (params) => {
        const textColor = item.text_color;
        const styling = params.row;
        const textColoring = styling[textColor] || '#000000';
        return (
          <div style={{ color: textColoring, cursor: 'pointer' }}>
            <Stack direction="row">
              <Typography fontWeight="500" fontSize="14px">
                {params.value}
              </Typography>
            </Stack>
          </div>
        );
      }
    }));
  }, [content]);

  const chart_data = useMemo(() => {
    if (apidata && content?.tbl_id) {
      const table_id = content?.tbl_id;
      const filtered_data = apidata.allTablesData.filter((items) => items?.[table_id]);
      return filtered_data?.[0]?.[table_id] || [];
    }
    return [];
  }, [content, apidata]);

  const modifiedData = chart_data.map((row, index) => ({
    ...row,
    id: index // Or use a unique field from your data
  }));

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 === 0 ? 'white-row' : 'smoke-white-row';
  };

  const handleclickfunc = (params) => {
    console.log('params', params);
  };

  return (
    <Box sx={{ height: 270, width: '100%' }}>
      <DataGrid
        rows={modifiedData}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5
            }
          }
        }}
        onCellClick={handleclickfunc}
        getRowClassName={getRowClassName}
        columnHeaderHeight={40}
        rowHeight={30}
        pageSizeOptions={[5]}
        disableRowSelectionOnClick
      />
    </Box>
  );
}
CellClickableTable.propTypes = {
  apidata: PropTypes.object,
  content: PropTypes.object,
  chart_data: PropTypes.array,
  setTableName: PropTypes.func,
  setSortColumnName: PropTypes.func,
  updateFieldValues: PropTypes.func,
  item: PropTypes.object
};
