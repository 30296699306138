import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
const LogoAndHeading = ({ content, apidata }) => {
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.chrt_id;
      const api_data = apidata?.chart;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata]);

  console.log('chart_data...', chart_data);
  return <Box sx={{ width: '100%' }}>This Box renders as an HTML section element.</Box>;
};

export default LogoAndHeading;
LogoAndHeading.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
