import * as React from 'react';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
export default function BoxTypeLabelWithBorder({ content }) {
  return (
    <Box
      sx={{
        backgroundColor: content?.label?.[0]?.bg_colr,
        padding: '20px',
        border: '1px solid #000000',
        marginLeft: '2rem',
        marginRight: '2rem'
      }}
      id="testing label"
    >
      <Typography
        sx={{
          color: content?.label?.[0]?.text_colr,
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: '19px',
          fontFamily: 'Arial, Helvetica, sans-serif'
        }}
      >
        {content?.label?.[0]?.lbl_descr}
      </Typography>
    </Box>
  );
}
BoxTypeLabelWithBorder.propTypes = {
  layout: PropTypes.object,
  content: PropTypes.object,
  apidata: PropTypes.object
};
