import React, { useEffect } from 'react';
// import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { ToGetDropDownValueAmount } from '../../features/counter/counterSlice';
import { ToGetDropDownValuemonthSingle } from '../../features/counter/counterSlice';
import { ToGetDropDownValueTopBottomPerformers } from '../../features/counter/counterSlice';
import { ToGetval_volValue } from '../../features/counter/counterSlice';
export default function BasicSingleminiDropDown({ fld_id, fld_l, apidata }) {
  const dispatch = useDispatch();

  const filteredArray = apidata?.fields?.filter((item) => item?.fld_id === `${fld_id}`);
  const DropValues = filteredArray?.[0]?.dropdown?.map((item) => item.display_name);
  const [age, setAge] = React.useState('');
  useEffect(() => {
    if (DropValues) {
      setAge(DropValues?.[0]);
    }
  }, [DropValues]);
  const handleChange = (event, fld_l_Values) => {
    if (fld_l_Values === 'Month') {
      const monthMapping = {
        Jan: '01',
        Feb: '02',
        Mar: '03',
        Apr: '04',
        May: '05',
        Jun: '06',
        Jul: '07',
        Aug: '08',
        Sep: '09',
        Oct: '10',
        Nov: '11',
        Dec: '12'
      };
      const SelectedMonth = monthMapping[event.target.value];
      dispatch(ToGetDropDownValuemonthSingle(SelectedMonth));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Value') {
      dispatch(ToGetDropDownValueAmount(event.target.value));
      setAge(event.target.value);
    } else if (fld_l_Values === 'Top/Bottom Performers') {
      setAge(event.target.value);
      dispatch(ToGetDropDownValueTopBottomPerformers(event.target.value));
    } else if (fld_l_Values === 'Volume/Value') {
      setAge(event.target.value);
      dispatch(ToGetval_volValue(event.target.value));
    }
  };
  return (
    <Box>
      <Stack direction="row" spacing={1}>
        <Typography fontWeight="bold">{fld_l}</Typography>
      </Stack>

      <FormControl sx={{ minWidth: '-webkit-fill-available' }} size="small">
        {/* <InputLabel id="demo-select-small-label">{fld_l}</InputLabel> */}
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={age}
          displayEmpty
          onChange={(event) => handleChange(event, fld_l)}
        >
          {DropValues?.map((item, index) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}
BasicSingleminiDropDown.propTypes = {
  fld_id: PropTypes.string,
  fld_l: PropTypes.string,
  layout: PropTypes.object,
  apidata: PropTypes.object,
  DefaltValueDropdown: PropTypes.array
};
