import React, { useMemo, memo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import SwapVertIcon from '@mui/icons-material/SwapVert';
import { ToGetSortColumnData } from '../../features/counter/counterSlice';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  OutlinedInput,
  ListItemText
} from '@mui/material';

const ChildGotTable = ({ content, apidata, setTableName, setSortColumnName, item }) => {
  const dispatch = useDispatch();
  const handleIconClick = (event, content, item) => {
    event.stopPropagation(); // Prevent the default sort action
    if (item.pm === 'p') {
      setTableName(item.p_tbl);
    } else if (item.pm === 'm') {
      setTableName(item.m_tbl);
    }
  };

  const handleIconClickSort = (event, content, item) => {
    event.stopPropagation(); // Prevent the default sort action
    setSortColumnName((prev) => {
      if (prev.column_order === 'asc' && prev.fld_id !== item.fld_id) {
        dispatch(
          ToGetSortColumnData({
            ...prev,
            column_order: 'desc',
            table_name: item.tbl_id,
            column_name: item.fld_id
          })
        );
        return { ...prev, column_order: 'desc', table_name: item.tbl_id, column_name: item.fld_id };
      } else if (prev.column_order === 'desc' && prev.column_name !== item.fld_id) {
        dispatch(
          ToGetSortColumnData({
            ...prev,
            column_order: 'desc',
            table_name: item.tbl_id,
            column_name: item.fld_id
          })
        );

        return { ...prev, column_order: 'desc', table_name: item.tbl_id, column_name: item.fld_id };
      } else if (prev.column_order === 'desc' && prev.column_name === item.fld_id) {
        dispatch(
          ToGetSortColumnData({
            ...prev,
            column_order: 'asc',
            table_name: item.tbl_id,
            column_name: item.fld_id
          })
        );

        return { ...prev, column_order: 'asc', table_name: item.tbl_id, column_name: item.fld_id };
      } else if (prev.column_order === 'asc' && prev.column_name === item.fld_id) {
        dispatch(
          ToGetSortColumnData({
            ...prev,
            column_order: 'desc',
            table_name: item.tbl_id,
            column_name: item.fld_id
          })
        );

        return { ...prev, column_order: 'desc', table_name: item.tbl_id, column_name: item.fld_id };
      }
    });
  };

  const chart_data = useMemo(() => {
    if (apidata?.got.length && content?.[0]?.own_table) {
      const table_id = content?.[0]?.own_table;
      const filtered_data = apidata.got.filter((items) => items?.[table_id]);
      return filtered_data?.[0]?.[table_id] || [];
    }
    return [];
  }, [content, apidata]);

  const NestedTableremove1 = (data, content) => {
    const keys = content?.[0]?.tableData
      ?.filter((item) => item.merge_row_cell === 1)
      .map((item) => item.fld_id);
    // Create Sets to keep track of unique values for each key
    const seenKeys = keys?.map(() => new Set());
    // Map over the array and modify the fields dynamically based on keys
    const result = data.map((item) => {
      // For each key, check if the value is already seen
      const newItem = { ...item };
      keys.forEach((key, index) => {
        const seenSet = seenKeys[index];
        const keyValue = keys
          .slice(0, index + 1)
          .map((k) => item[k])
          .join('-');
        const isKeySeen = seenSet.has(keyValue);
        // If the value has been seen, replace it with an empty string
        newItem[key] = isKeySeen ? '' : item[key];
        // Add the key combination to the Set
        seenSet.add(keyValue);
      });
      return newItem;
    });

    return result;
  };
  const uniqueItems1 = NestedTableremove1(chart_data, content);
  const modifiedData = uniqueItems1.map((row, index) => ({
    ...row,
    id: index // Or use a unique field from your data
  }));

  const column1s = useMemo(() => {
    if (!content || !content[0] || !content[0].tableData) {
      // Return an empty array if content or tableData is not available
      return [];
    }
    return content?.[0]?.tableData?.map((item, index) => ({
      field: item.fld_id,
      headerName: content?.[0]?.tableFieldData?.[index]?.fld_l,
      headerAlign: 'center',
      align: 'center',
      // minWidth: 200,
      flex: 1,
      sortable: false,
      renderHeader: (params) => (
        <div>
          <Stack direction="column" alignItems="center">
            <Box sx={{ width: '100%' }}>
              <Stack spacing={1}>
                {/* <Box id="testingQ" height="14px">
                                    <Typography fontWeight="600" fontSize="0.8rem">
                                        {item?.prnt_fld_id}
                                    </Typography>
                                </Box> */}

                <Typography fontWeight="bold" fontSize="0.9rem" color={item?.col_hdr_text_colr}>
                  {params?.colDef?.headerName}
                  {item.sort_i === 1 ? (
                    <SwapVertIcon
                      sx={{ cursor: 'pointer', fontSize: '17px' }}
                      onClick={(event) => {
                        handleIconClickSort(event, content, item);
                      }}
                    />
                  ) : (
                    ''
                  )}
                </Typography>
              </Stack>
            </Box>

            {item.pm === 'p' && (
              <AddBoxOutlinedIcon
                sx={{ cursor: 'pointer', fontSize: '17px' }}
                onClick={(event) => {
                  handleIconClick(event, content, item);
                }}
              />
            )}
            {item.pm === 'm' && (
              <IndeterminateCheckBoxOutlinedIcon
                sx={{ cursor: 'pointer', fontSize: '17px' }}
                onClick={(event) => {
                  handleIconClick(event, content, item);
                }}
              />
            )}
          </Stack>
        </div>
      ),
      renderCell: (params) => {
        const textColor = item.text_color;
        const styling = params.row;
        const textColoring = styling[textColor] || '#000000';
        return (
          <div style={{ color: textColoring }}>
            <Stack direction="row">
              <Typography fontWeight="600" fontSize="0.8rem">
                {params.value}
              </Typography>
            </Stack>
          </div>
        );
      }
    }));
  }, [content]);
  const NestedTable = (content) => {
    return content?.[0]?.tableData?.[0]?.col_hdr_bg_colr;
  };

  const [visibleColumns, setVisibleColumns] = useState(column1s);
  const [tempSelectedColumns, setTempSelectedColumns] = useState([]);

  useEffect(() => {
    if (column1s && column1s.length > 0) {
      const headers = column1s.map((col) => col.headerName);
      setTempSelectedColumns(headers); // Set to all column headers
      setVisibleColumns(column1s); // Also update visibleColumns
    }
  }, [column1s]);
  // Column toggle handler
  const handleColumnToggle = (event) => {
    const { value } = event.target;
    if (value.includes('Select All')) {
      setTempSelectedColumns(
        tempSelectedColumns.length === column1s.length ? [] : column1s.map((col) => col.headerName)
      );
    } else {
      setTempSelectedColumns(value);
    }
  };

  // Apply column visibility changes
  const applyChanges = () => {
    setVisibleColumns(column1s.filter((column) => tempSelectedColumns.includes(column.headerName)));
  };

  return (
    <Box sx={{ height: 500, width: '100%' }}>
      {content?.[0]?.column_drp_dn_filter === 1 && (
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          backgroundColor="#f3faf9"
        >
          <Typography marginLeft="10px">{item?.lbl}</Typography>
          <FormControl sx={{ m: 1, width: 200 }}>
            {/* <InputLabel sx={{ color: '#0093d2' }}>Columns</InputLabel> */}
            <Select
              multiple
              value={tempSelectedColumns}
              onChange={handleColumnToggle}
              input={<OutlinedInput label="Columns" />}
              // renderValue={(selected) => selected.join(', ')}
              renderValue={(selected) =>
                selected.length === column1s.length ? 'All' : selected.join(', ')
              }
              sx={{ border: '1px solid #0093d2' }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    backgroundColor: '#f8fcfc' // Dropdown list background color
                  }
                }
              }}
            >
              <MenuItem value="Select All">
                <Checkbox checked={tempSelectedColumns?.length === column1s?.length} />
                <ListItemText primary="All" />
              </MenuItem>
              {column1s?.map((column) => (
                <MenuItem key={column.field} value={column.headerName}>
                  <Checkbox checked={tempSelectedColumns.includes(column.headerName)} />
                  <ListItemText primary={column.headerName} />
                </MenuItem>
              ))}
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-around"
                bgcolor="#f8fcfc"
              >
                <Button variant="outlined">Cancel</Button>
                <Button variant="contained" onClick={applyChanges}>
                  Apply
                </Button>
              </Box>
            </Select>
          </FormControl>
        </Box>
      )}

      <DataGrid
        rows={modifiedData || []}
        columns={visibleColumns || []}
        rowHeight={30} // Set the height of the rows
        pageSizeOptions={[5]}
        checkboxSelection={false} // Disable checkboxes for row selection
        disableRowSelectionOnClick
        pagination={false}
        showCellVerticalBorder
        columnHeaderHeight={50}
        showColumnVerticalBorder
        getRowClassName={(params) => {
          // Check if it's the last row
          if (params.indexRelativeToCurrentPage === modifiedData.length - 1) {
            return 'last-row';
          }
          return '';
        }}
        sx={{
          '& .MuiDataGrid-columnHeaders': {
            borderBottom: '1px solid #E0E0E0',

            backgroundColor: () => NestedTable(content)
            // Border for column headers
          },
          '& .MuiDataGrid-cell': {
            border: '1px solid #E0E0E0',
            borderBottom: 'none'
          },
          '& .no-border': {
            border: 'none', // No border for empty cells/
            borderRight: '1px solid #E0E0E0'
          },
          '& .last-row': {
            borderBottom: '1px solid #E0E0E0' // Example styling for the last row
          }
        }}
        getCellClassName={(params) => {
          //get all the columns names to apply no-border condition
          const keys = content[0]?.tableData
            ?.filter((item) => item.merge_row_cell === 1)
            .map((item) => item.fld_id);
          // List of column fields where you want to apply the no-border condition
          const targetColumns = keys; // Replace with actual column field names
          // Apply no-border class if the value is empty and the column is in the targetColumns list
          if (!params.value && targetColumns.includes(params.field)) {
            return 'no-border';
          }
          return ''; // Return empty string if no condition is met
        }}
      />
    </Box>
  );
};
ChildGotTable.propTypes = {
  apidata: PropTypes.object,
  content: PropTypes.object,
  chart_data: PropTypes.array,
  setTableName: PropTypes.func,
  setSortColumnName: PropTypes.func,
  updateFieldValues: PropTypes.func,
  item: PropTypes.object
};
export default memo(ChildGotTable);
