import { useMemo, useCallback } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import PropTypes from 'prop-types';

const AggridColumnPinning = ({ layout, apidata, content }) => {
  //row data
  const chart_data = useMemo(() => {
    if (content) {
      const table_id = content?.tbl_id;
      const api_data = apidata?.allTablesData;
      let filterd_data = api_data?.filter((items) => items?.[table_id]);
      return filterd_data?.[0]?.[table_id];
    }
  }, [apidata, layout?.sctn_id]);

  const columns = useMemo(() => {
    return content?.tableData?.map((item, index) => ({
      field: item.fld_id,
      headerName: content?.tableFieldData?.[index]?.fld_l,
      pinned: item.is_pin_column === 1 ? 'left' : null
    }));
  }, []);

  const getRowStyle = (params) => {
    return { background: params.node.rowIndex % 2 === 0 ? '#ffffff' : '#e6e6e6' };
  };

  const ColumnHeaderColor = useCallback((content) => {
    const PinnedColumns = content?.tableData?.map((item) => item.col_hdr_bg_colr);
    return PinnedColumns[0] || 'white';
  }, []);

  const ColumnHeaderTextColor = useCallback((content) => {
    const Columnstextcolor = content?.tableData?.map((item) => item.col_hdr_text_colr);
    return Columnstextcolor[0] || 'black';
  }, []);

  return (
    <>
      <div
        style={{
          height: '40px',
          backgroundColor: '#e8e8e8',
          marginBottom: '5px',
          padding: '5px',
          fontSize: '16px',
          lineHeight: '26px',
          color: content?.tbl_nm_colr || 'black',
          border: '2px solid #b4b4b4'
        }}
      >
        {content?.tbl_nm}
      </div>
      <div
        className="ag-theme-quartz"
        style={{
          height: 500,
          width: '100%',
          '--ag-header-background-color': ColumnHeaderColor(content),
          '--ag-header-foreground-color': ColumnHeaderTextColor(content) // AG Grid CSS variable for header background
        }}
      >
        <AgGridReact rowData={chart_data} columnDefs={columns} getRowStyle={getRowStyle} />
      </div>
    </>
  );
};

export default AggridColumnPinning;
AggridColumnPinning.propTypes = {
  layout: PropTypes.object,
  apidata: PropTypes.object,
  content: PropTypes.object,
  cell: PropTypes.shape({
    column: PropTypes.object.isRequired,
    row: PropTypes.object.isRequired,
    getValue: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
    // Add other expected properties with appropriate types
  }).isRequired
};
